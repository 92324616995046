<template>
  <div>
    <template v-if="editable">
      <template v-for="(lookTag, index) of lookTagsToSubmit">
        <Input
          v-for="(value, name) of $_lookTagInputs(lookTag)"
          :key="name + index"
          :name="name"
          :value="value"
        />
      </template>

      <Tag
        v-if="selectedUnconfirmedLookTag"
        :uuid="selectedUnconfirmedLookTag.uuid"
        :editable="editable"
        :likeable="likeable"
        :wishlistable="wishlistable"
        :max-image-size="maxImageSize"
      />
    </template>

    <Tag
      v-for="lookTag of lookTagsToShow"
      :key="lookTag.uuid"
      :uuid="lookTag.uuid"
      :editable="editable"
      :likeable="likeable"
      :wishlistable="wishlistable"
      :max-image-size="maxImageSize"
    />
  </div>
</template>

<script>
import _ from 'lodash'

import Input from './Input'
import Tag from './Tag'

export default {
  components: { Input, Tag },

  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    likeable: {
      type: Boolean,
      default: false,
    },
    wishlistable: {
      type: Boolean,
      default: false,
    },
    maxImageSize: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      allowedLookTagKeys: [
        'id',
        'uuid',
        'parent_id',
        'parent_uuid',
        'product_id',
        'x1',
        'y1',
        'x2',
        'y2',
        'title',
        'url',
        '_destroy',
      ],
      allowedProductKeys: ['title', 'url', 'image'],
      allowedAttributesKeys: ['name', 'value'],
    }
  },

  computed: {
    lookTags() {
      return this.$store.state.lookTags
    },

    lookTagsToShow() {
      return this.lookTags.filter(
        item => item.confirmed && !item._destroy && !item.parent_uuid
      )
    },

    lookTagsToSubmit() {
      return this.lookTags.filter(item => item.confirmed || item._destroy)
    },

    selectedUnconfirmedLookTag() {
      const selectedUuid = this.$store.state.selectedLookTagUuid
      if (!selectedUuid) return null

      const lookTag = this.lookTags.find(item => item.uuid === selectedUuid)
      return lookTag.confirmed || lookTag._destroy ? null : lookTag
    },
  },

  methods: {
    $_lookTagInputs(lookTag) {
      const result = {}

      const lookTagInputs = _.pick(lookTag, this.allowedLookTagKeys)
      lookTagInputs.attributes_data = lookTag.attributes_data.map(attr =>
        _.pick(attr, this.allowedAttributesKeys)
      )

      Object.keys(lookTagInputs).forEach(key => {
        const name = `look[look_tags_attributes][][${key}]`
        result[String(name)] = lookTagInputs[String(key)]
      })

      if (lookTag.type === 'new_product') {
        const product = lookTag.product_attributes
        const productInputs = _.pick(product, this.allowedProductKeys)
        productInputs.attributes_data = product.attributes_data.map(attr =>
          _.pick(attr, this.allowedAttributesKeys)
        )

        Object.keys(productInputs).forEach(key => {
          const name = `look[look_tags_attributes][][product_attributes][${key}]`
          result[String(name)] = productInputs[String(key)]
        })
      }

      return result
    },
  },
}
</script>
