<template>
  <form class="flex" @submit.prevent="$_submit">
    <div class="flex-grow">
      <input
        id="wishlist_item_title"
        v-model="wishlistTitle"
        type="text"
        name="wishlist_item[title]"
        class="form-field"
        :placeholder="$t('wishlist_button.new_wishlist_title')"
      />

      <label class="text-sm">
        <input
          id="wishlist_item_private"
          v-model="wishlistPrivate"
          type="checkbox"
          name="wishlist_item[private]"
          class="form-checkbox"
        />
        {{ $t('wishlist_button.wishlist_private') }}
      </label>
    </div>

    <div class="flex-shrink-0 ml-1">
      <button class="btn btn-blue" type="submit">
        {{ $t('shared.add') }}
      </button>
    </div>
  </form>
</template>

<script>
import authenticityToken from '../../mixins/authenticityToken'

export default {
  mixins: [authenticityToken],

  props: {
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      wishlistTitle: null,
      wishlistPrivate: false,
    }
  },

  methods: {
    $_submit() {
      this.$http
        .post(
          '/js/wishlist_items',
          {
            target_id: this.targetId,
            target_type: this.targetType,
            wishlist_title: this.wishlistTitle,
            wishlist_private: this.wishlistPrivate,
          },
          { headers: { 'X-CSRF-Token': this.$_authenticityToken() } }
        )
        .then(response => {
          this.wishlistTitle = null
          this.wishlistPrivate = false
          this.$emit('wishlists-updated', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
