<template>
  <div>
    <Attribute
      v-for="attribute of attributesData"
      :key="attribute.uuid"
      :uuid="attribute.uuid"
      :name="attribute.name"
      :value="attribute.value"
      :focused="attribute.focused"
      :show-edit-form="showEditForm"
      @update-name="$_emitUpdateName"
      @update-value="$_emitUpdateValue"
      @remove-attribute="$_emitRemoveAttribute"
      @value-enter="$_emitValueEnter"
      @reset-focus="$_emitResetFocus"
    />

    <div v-if="showEditForm" class="text-sm">
      <a
        href="#"
        class="text-gray-600 hover:text-blue-700"
        @click.prevent="$_emitAddEmptyAttribute"
      >
        <i class="fas fa-plus mr-1"></i>
        {{ $t('entity_attributes.add_attribute') }}
      </a>
    </div>
  </div>
</template>

<script>
import Attribute from './Attribute'

export default {
  components: { Attribute },

  props: {
    attributesData: {
      type: Array,
      required: true,
    },
    showEditForm: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    $_emitValueEnter(uuid) {
      this.$emit('value-enter', uuid)
    },

    $_emitAddEmptyAttribute() {
      this.$emit('add-empty-attribute')
    },

    $_emitRemoveAttribute(uuid) {
      this.$emit('remove-attribute', uuid)
    },

    $_emitUpdateName(uuid, newName) {
      this.$emit('update-name', uuid, newName)
    },

    $_emitUpdateValue(uuid, newValue) {
      this.$emit('update-value', uuid, newValue)
    },

    $_emitResetFocus(uuid) {
      this.$emit('reset-focus', uuid)
    },
  },
}
</script>
