import uuidv4 from './uuidv4'

export default {
  mixins: [uuidv4],

  methods: {
    $_defaultLookTag(data = {}, lookTagType = null) {
      const lookTagData = {
        id: '',
        uuid: this.$_uuidv4(),
        parent_id: null,
        parent_uuid: null,
        product_id: null,
        type: null,
        confirmed: false,
        _destroy: false,
        x1: null,
        y1: null,
        x2: null,
        y2: null,
        title: null,
        url: null,
        ...data,
      }

      const productData = {
        title: null,
        url: null,
        image: null,
        thumbnail_image_url: null,
        thumbnail_image_srcset: null,
        ...data.product_attributes,
      }

      const entityAttributes = [
        {
          uuid: this.$_uuidv4(),
          name: 'Type',
          value: lookTagType || '',
        },
        {
          uuid: this.$_uuidv4(),
          name: 'Brand',
          value: '',
        },
      ]

      return {
        ...lookTagData,
        attributes_data: entityAttributes,
        product_attributes: {
          ...productData,
          attributes_data: entityAttributes,
        },
      }
    },
  },
}
