<template>
  <span class="text-sm">
    <template v-if="editable">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span class="mr-1" v-html="profile.avatar_html" />
      {{ profile.full_name }}
    </template>
    <template v-else>
      <a :href="`/${profile.username}`" class="hover:underline">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="mr-1" v-html="profile.avatar_html" />
        {{ profile.full_name }}
      </a>
    </template>
  </span>
</template>

<script>
export default {
  props: {
    profile: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
