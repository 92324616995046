<template>
  <vue-autosuggest
    ref="autosuggest"
    v-model="inputValue"
    :input-props="{
      id: inputId,
      class: 'form-field',
      disabled: inputDisabled,
    }"
    :suggestions="suggestions"
    :should-render-suggestions="shouldRenderSuggestion"
    @input="$_fetchSuggestions"
    @selected="$_setInputValue"
    @item-changed="$_setInputValue"
    @keydown.enter.prevent="$_emitEnter"
    @keydown.tab="$_closeSuggestions"
  >
    <div slot-scope="{ suggestion }" class="autosuggest-result">
      {{ suggestion.item }}
    </div>
  </vue-autosuggest>
</template>

<script>
import _ from 'lodash'

import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    VueAutosuggest,
  },

  props: {
    inputId: {
      type: String,
      required: true,
    },
    inputDisabled: {
      type: Boolean,
      default: false,
    },
    queryUrl: {
      type: String,
      required: true,
    },
    queryParams: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      suggestions: [],
      forceClose: false,
      shouldRenderSuggestion: (totalResults, loading) => {
        return totalResults > 0 && !loading && !this.forceClose
      },
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value
      },

      set(newValue) {
        this.forceClose = false // Allow showing suggestions when typing
        this.$emit('autocomplete-input', newValue)
      },
    },
  },

  watch: {
    queryParams: function (newParams, oldParams) {
      if (JSON.stringify(newParams) !== JSON.stringify(oldParams)) {
        this.suggestions = []
      }
    },
  },

  methods: {
    $focus() {
      this.$refs.autosuggest.$el.querySelector('input').focus()
    },

    $_fetchSuggestions: _.debounce(function (query) {
      this.$http
        .get(this.queryUrl, {
          params: { ...this.queryParams, query: query },
        })
        .then(response => {
          this.suggestions = [{ data: response.data.suggestions }]
        })
        .catch(error => console.error(error))
    }, 250),

    $_setInputValue(item) {
      if (item) {
        this.inputValue = item.item
      } else {
        this.inputValue = this.$refs.autosuggest.searchInputOriginal
      }
    },

    $_emitEnter() {
      this.$emit('autocomplete-enter')
    },

    $_closeSuggestions() {
      this.forceClose = true
    },
  },
}
</script>
