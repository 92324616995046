import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggler', 'content']

  show(event) {
    event.preventDefault()
    this.togglerTarget.classList.add('hidden')
    this.contentTarget.classList.remove('hidden')
  }
}
