<template>
  <v-select
    :clearable="false"
    :filterable="false"
    :options="options"
    :value="product"
    :placeholder="$t('look_tags.edit.product')"
    label="title"
    @search="$_fetchOptions"
    @input="$_emitSelect"
  >
    <template #option="option">
      <div class="flex items-center">
        <img
          v-if="option.thumbnail_image_url"
          :src="option.thumbnail_image_url"
          class="rounded w-10 mr-2"
        />
        <div class="flex-grow truncate">
          {{ option.title }}
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: { vSelect },

  props: {
    profileId: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: [],
    }
  },

  methods: {
    $_fetchOptions(search, loading) {
      if (search) {
        loading(true)
        this.$http
          .get('/js/autocomplete/products', {
            params: { user_id: this.profileId, query: search },
          })
          .then(response => {
            this.options = response.data.suggestions
          })
          .catch(error => {
            console.error(error)
            this.options = []
          })
          .finally(loading(false))
      } else {
        this.options = []
      }
    },

    $_emitSelect(product) {
      this.$emit('select', product)
    },
  },
}
</script>
