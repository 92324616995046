<template>
  <v-select
    :clearable="false"
    :filterable="false"
    :options="options"
    :value="profile"
    :placeholder="$t('look_tags.edit.profile')"
    label="full_name"
    @search="$_fetchOptions"
    @input="$_emitSelect"
  >
    <template #selected-option="option">
      <ProfileSelectOption :option="option" />
    </template>
    <template #option="option">
      <ProfileSelectOption :option="option" />
    </template>
  </v-select>
</template>

<script>
import ProfileSelectOption from './ProfileSelectOption'

import vSelect from 'vue-select'

export default {
  components: { ProfileSelectOption, vSelect },

  props: {
    profile: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      options: [],
    }
  },

  methods: {
    $_fetchOptions(search, loading) {
      if (search) {
        loading(true)
        this.$http
          .get('/js/autocomplete/profiles', {
            params: { query: search },
          })
          .then(response => {
            this.options = response.data.suggestions
          })
          .catch(error => {
            console.error(error)
            this.options = []
          })
          .finally(loading(false))
      } else {
        this.options = []
      }
    },

    $_emitSelect(profile) {
      this.$emit('select', profile)
    },
  },
}
</script>
