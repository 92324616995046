<template>
  <div class="text-center">
    <div class="relative inline-block rounded overflow-hidden">
      <img :src="imageUrl" class="select-none blur" />
      <div class="absolute inset-0 bg-black opacity-75"></div>
      <div class="absolute inset-0 flex flex-col justify-center items-center">
        <span class="text-white">
          {{ $t('image_uploader.processing') }}
        </span>

        <div class="relative overflow-x-hidden mt-4 h-1 w-4/5">
          <div class="absolute opacity-50 bg-blue-400 h-1 w-full"></div>
          <div class="absolute bg-blue-400 h-1 animation-increase"></div>
          <div class="absolute bg-blue-400 h-1 animation-decrease"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    processingCallback: {
      type: Function,
      default: null,
    },
  },

  mounted() {
    this.processingCallback(this.imageUrl, this.$_processedCallback)
  },

  methods: {
    $_processedCallback() {
      this.$emit('processed')
    },
  },
}
</script>
