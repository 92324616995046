<template>
  <div class="break-words">
    <div v-if="editable && isSelected">
      <div class="flex items-center mb-2">
        <div class="w-10 mr-2">
          <label class="form-label">
            {{ $t('activerecord.attributes.look_tag.title') }}
          </label>
        </div>
        <div class="flex-grow">
          <input
            v-model="titleInputValue"
            type="text"
            class="form-field"
            @keydown.enter.prevent="$_onTitleInputEnter"
          />
        </div>
      </div>
      <div v-if="urlAllowed" class="flex items-center mb-2">
        <div class="w-10 mr-2">
          <label class="form-label">
            {{ $t('activerecord.attributes.look_tag.url') }}
          </label>
        </div>
        <div class="flex-grow">
          <input
            ref="urlInput"
            v-model="urlInputValue"
            type="text"
            class="form-field"
            @keydown.enter.prevent="$_onUrlInputEnter"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="font-bold text-lg mb-1">
        {{ lookTag.title }}
      </div>
      <TagUrl v-if="lookTag.url" :url="lookTag.url" :editable="editable" />
    </div>

    <EntityAttributes
      :attributes-data="lookTag.attributes_data"
      :show-edit-form="editable && isSelected"
      @update-name="$_updateAttributeName"
      @update-value="$_updateAttributeValue"
      @remove-attribute="$_removeAttribute"
      @add-empty-attribute="$_addEmptyAttribute"
      @value-enter="$_onAttributeValueEnter"
      @reset-focus="$_resetAttributeFocus"
    />
  </div>
</template>

<script>
import TagUrl from './TagUrl'

import EntityAttributes from '../EntityAttributes'

import uuidv4 from '../../mixins/uuidv4'

export default {
  components: { TagUrl, EntityAttributes },

  mixins: [uuidv4],

  props: {
    uuid: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    lookTag() {
      return this.$store.getters.lookTagByUuid(this.uuid)
    },

    isSelected() {
      return this.$store.state.selectedLookTagUuid === this.uuid
    },

    urlAllowed() {
      const allowedTypes = ['business', 'admin']
      return allowedTypes.includes(this.$store.state.currentUserType)
    },

    titleInputValue: {
      get() {
        return this.lookTag.title
      },

      set(newValue) {
        this.$store.commit('updateLookTag', {
          uuid: this.uuid,
          title: newValue,
        })
      },
    },

    urlInputValue: {
      get() {
        return this.lookTag.url
      },

      set(newValue) {
        this.$store.commit('updateLookTag', {
          uuid: this.uuid,
          url: newValue,
        })
      },
    },
  },

  methods: {
    $_onTitleInputEnter() {
      if (this.urlAllowed) {
        this.$refs.urlInput.focus()
      } else {
        this.$_onUrlInputEnter()
      }
    },

    $_onUrlInputEnter() {
      const lookTagAttributes = this.lookTag.attributes_data
      if (lookTagAttributes.length > 0) {
        const nextUuid = lookTagAttributes[0].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_onAttributeValueEnter(uuid) {
      const lookTagAttributes = this.lookTag.attributes_data
      const index = lookTagAttributes.findIndex(item => item.uuid === uuid)
      if (index + 1 < lookTagAttributes.length) {
        const nextUuid = lookTagAttributes[index + 1].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_addEmptyAttribute() {
      this.$store.commit('addLookTagAttribute', {
        lookTagUuid: this.uuid,
        data: {
          uuid: this.$_uuidv4(),
          name: '',
          value: '',
          focused: true,
        },
      })
    },

    $_removeAttribute(uuid) {
      this.$store.commit('removeLookTagAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
      })
    },

    $_updateAttributeName(uuid, newName) {
      this.$store.commit('updateLookTagAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { name: newName },
      })
    },

    $_updateAttributeValue(uuid, newValue) {
      this.$store.commit('updateLookTagAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { value: newValue },
      })
    },

    $_focusAttribute(uuid) {
      this.$store.commit('updateLookTagAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { focused: true },
      })
    },

    $_resetAttributeFocus(uuid) {
      this.$store.commit('updateLookTagAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { focused: false },
      })
    },
  },
}
</script>
