<template>
  <div>
    <div class="flex items-center mb-2">
      <div class="w-10 mr-2">
        <label class="form-label">
          {{ $t('activerecord.attributes.product.title') }}
        </label>
      </div>
      <div class="flex-grow">
        <input
          v-model="titleInputValue"
          name="product[title]"
          type="text"
          class="form-field"
          @keydown.enter.prevent="$_onTitleInputEnter"
        />
      </div>
    </div>

    <div class="flex items-center mb-2">
      <div class="w-10 mr-2">
        <label class="form-label">
          {{ $t('activerecord.attributes.product.url') }}
        </label>
      </div>
      <div class="flex-grow">
        <input
          ref="urlInput"
          v-model="urlInputValue"
          name="product[url]"
          type="text"
          class="form-field"
          @keydown.enter.prevent="$_onUrlInputEnter"
        />
      </div>
    </div>

    <EntityAttributes
      :attributes-data="attributesData"
      :show-edit-form="true"
      @update-name="$_updateAttributeName"
      @update-value="$_updateAttributeValue"
      @remove-attribute="$_removeAttribute"
      @add-empty-attribute="$_addEmptyAttribute"
      @value-enter="$_onAttributeValueEnter"
      @reset-focus="$_resetAttributeFocus"
    />

    <input type="hidden" name="product[attributes_data]" :value="inputValue" />
  </div>
</template>

<script>
import EntityAttributes from '../EntityAttributes'

import uuidv4 from '../../mixins/uuidv4'

import _ from 'lodash'

export default {
  components: { EntityAttributes },

  mixins: [uuidv4],

  props: {
    productTitle: {
      type: String,
      default: null,
    },
    productUrl: {
      type: String,
      default: null,
    },
    productAttributes: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      titleInputValue: this.productTitle,
      urlInputValue: this.productUrl,
      attributesData: this.$_buildData(this.productAttributes),
    }
  },

  computed: {
    inputValue() {
      const allowedKeys = ['name', 'value']
      const filteredData = this.attributesData.map(attribute =>
        _.pick(attribute, allowedKeys)
      )
      return JSON.stringify(filteredData)
    },
  },

  methods: {
    $_buildData(productAttributes) {
      return productAttributes.map(attribute => ({
        uuid: attribute.id || this.$_uuidv4(),
        name: attribute.name || '',
        value: attribute.value || '',
        focused: false,
      }))
    },

    $_attributeIndexByUuid(uuid) {
      return this.attributesData.findIndex(item => item.uuid === uuid)
    },

    $_onTitleInputEnter() {
      this.$refs.urlInput.focus()
    },

    $_onUrlInputEnter() {
      if (this.attributesData.length > 0) {
        const nextUuid = this.attributesData[0].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_onAttributeValueEnter(uuid) {
      const index = this.$_attributeIndexByUuid(uuid)
      if (index + 1 < this.attributesData.length) {
        const nextUuid = this.attributesData[index + 1].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_addEmptyAttribute() {
      this.attributesData.push({
        uuid: this.$_uuidv4(),
        name: '',
        value: '',
        focused: true,
      })
    },

    $_removeAttribute(uuid) {
      const index = this.$_attributeIndexByUuid(uuid)
      this.attributesData.splice(index, 1)
    },

    $_updateAttribute(uuid, data) {
      const index = this.$_attributeIndexByUuid(uuid)
      this.attributesData.splice(index, 1, {
        ...this.attributesData[parseInt(index)],
        ...data,
      })
    },

    $_updateAttributeName(uuid, newName) {
      this.$_updateAttribute(uuid, { name: newName })
    },

    $_updateAttributeValue(uuid, newValue) {
      this.$_updateAttribute(uuid, { value: newValue })
    },

    $_focusAttribute(uuid) {
      this.$_updateAttribute(uuid, { focused: true })
    },

    $_resetAttributeFocus(uuid) {
      this.$_updateAttribute(uuid, { focused: false })
    },
  },
}
</script>
