<template>
  <button
    v-if="isFollowing"
    class="btn btn-sm btn-outline-gray"
    @click="$_unfollow"
  >
    {{ $t('follow_button.following') }}
  </button>
  <button v-else class="btn btn-sm btn-blue" @click="$_follow">
    {{ $t('follow_button.follow') }}
  </button>
</template>

<script>
import authenticityToken from '../../mixins/authenticityToken'

export default {
  mixins: [authenticityToken],

  props: {
    following: {
      type: Boolean,
      required: true,
    },
    followeeId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isFollowing: this.following,
    }
  },

  methods: {
    $_follow() {
      this.$http
        .post(
          '/js/followings',
          { followee_id: this.followeeId },
          { headers: { 'X-CSRF-Token': this.$_authenticityToken() } }
        )
        .then(response => {
          this.isFollowing = response.data.following
        })
        .catch(error => console.error(error))
    },

    $_unfollow() {
      this.$http
        .delete('/js/followings', {
          params: { followee_id: this.followeeId },
          headers: { 'X-CSRF-Token': this.$_authenticityToken() },
        })
        .then(response => {
          this.isFollowing = response.data.following
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
