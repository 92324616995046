<template>
  <a
    v-if="isLiked"
    href="#"
    class="text-xl text-red-600"
    @click.prevent="$_unlike"
  >
    <i class="fas fa-heart" :title="$t('like_button.liked')"></i>
  </a>

  <a v-else href="#" class="text-xl text-gray-600" @click.prevent="$_like">
    <i class="far fa-heart" :title="$t('like_button.like')"></i>
  </a>
</template>

<script>
import authenticityToken from '../../mixins/authenticityToken'

export default {
  mixins: [authenticityToken],

  props: {
    liked: {
      type: Boolean,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLiked: this.liked,
    }
  },

  methods: {
    $_like() {
      this.$http
        .post(
          '/js/likes',
          { target_id: this.targetId, target_type: this.targetType },
          { headers: { 'X-CSRF-Token': this.$_authenticityToken() } }
        )
        .then(response => {
          this.isLiked = response.data.liked
        })
        .catch(error => console.error(error))
    },

    $_unlike() {
      this.$http
        .delete('/js/likes', {
          params: { target_id: this.targetId, target_type: this.targetType },
          headers: { 'X-CSRF-Token': this.$_authenticityToken() },
        })
        .then(response => {
          this.isLiked = response.data.liked
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
