<template>
  <vue2Dropzone
    :id="dropzoneId"
    ref="dropzone"
    :options="dropzoneOptions"
    :include-styling="false"
    :use-custom-slot="true"
    @vdropzone-file-added="$_onDropFileAdded"
    @vdropzone-success="$_onDropSuccess"
    @vdropzone-error="$_onDropError"
    @vdropzone-sending="$_onDropSending"
    @vdropzone-complete="$_onDropComplete"
    @vdropzone-total-upload-progress="$_onDropProgress"
  >
    <div class="dropzone__opener hidden"></div>

    <input
      v-if="inputName"
      type="hidden"
      :name="inputName"
      :value="imageData"
    />

    <Cropping
      v-if="state === 'cropping'"
      :file="cropFile"
      :aspect-ratio="cropAspectRatio"
      :min-width="cropMinWidth"
      :min-height="cropMinHeight"
      :max-width="cropMaxWidth"
      :max-height="cropMaxHeight"
      @cropped="$_onCropSuccess"
      @error="$_onCropError"
      @canceled="$_onCanceled"
    />
    <Uploading
      v-else-if="state === 'uploading'"
      :progress="progress"
      @canceled="$_onCanceled"
    />
    <Processing
      v-else-if="state === 'processing'"
      :image-url="imageUrl"
      :processing-callback="processingCallback"
      @processed="$_onProcessed"
    />
    <Uploaded
      v-else-if="state === 'uploaded'"
      :image-url="imageUrl"
      @canceled="$_onCanceled"
    >
      <template #uploaded="slotProps">
        <slot name="uploaded" :image-url="slotProps.imageUrl" />
      </template>
    </Uploaded>
    <Error
      v-else-if="state === 'error'"
      :error="error"
      @canceled="$_onCanceled"
    />
    <Dropzone
      v-else
      :max-size="maxSize"
      @open-file-selector="$_openFileSelector"
      @url-blob="$_onDropUrlBlob"
      @url-error="$_onDropUrlError"
    />
  </vue2Dropzone>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

import Dropzone from './Dropzone'
import Cropping from './Cropping'
import Uploading from './Uploading'
import Processing from './Processing'
import Uploaded from './Uploaded'
import Error from './Error'

import authenticityToken from '../../mixins/authenticityToken'

export default {
  components: {
    vue2Dropzone,
    Dropzone,
    Cropping,
    Uploading,
    Processing,
    Uploaded,
    Error,
  },

  mixins: [authenticityToken],

  props: {
    dropzoneId: {
      type: String,
      required: true,
    },
    uploadUrl: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      default: null,
    },
    cachedImageData: {
      type: String,
      default: null,
    },
    cachedImageUrl: {
      type: String,
      default: null,
    },
    maxSize: {
      type: Number,
      required: true,
    },
    cropEnabled: {
      type: Boolean,
      default: false,
    },
    cropAspectRatio: {
      type: Number,
      default: null,
    },
    cropMinWidth: {
      type: Number,
      default: null,
    },
    cropMinHeight: {
      type: Number,
      default: null,
    },
    cropMaxWidth: {
      type: Number,
      default: null,
    },
    cropMaxHeight: {
      type: Number,
      default: null,
    },
    processingCallback: {
      type: Function,
      default: null,
    },
  },

  data() {
    const dropzoneOptions = {
      url: '/uploads/looks',
      withCredentials: true,
      headers: {
        'X-CSRF-Token': this.$_authenticityToken(),
      },
      clickable: '.dropzone__opener',
      dictDefaultMessage: '',
      uploadMultiple: false,
      maxFiles: 1,
      maxFilesize: this.maxSize,
      createImageThumbnails: false,
      previewTemplate: '<div></div>',
      acceptedFiles: 'image/jpeg,image/jpg,image/png',
    }

    if (this.cropEnabled) {
      dropzoneOptions.transformFile = this.$_cropFile
    }

    const data = {
      state: 'initial',
      error: '',
      progress: 0.0,
      imageUrl: this.cachedImageUrl,
      imageData: this.cachedImageData,
      cropFile: null,
      cropDoneCallback: null,
      dropzoneOptions,
    }

    return data
  },

  computed: {
    dropzone() {
      return this.$refs.dropzone
    },
  },

  mounted() {
    if (this.imageUrl) {
      this.$_goToStep('uploaded')
    }
  },

  methods: {
    $_goToStep(step) {
      this.state = step
      this.$emit('state-change', step === 'uploaded')
    },

    $_openFileSelector() {
      this.dropzone.dropzone.hiddenFileInput.click()
    },

    $_onDropUrlBlob(blob) {
      this.dropzone.addFile(blob)
    },

    $_onDropUrlError(message) {
      this.error = message
      this.$_goToStep('error')
    },

    $_cropFile(file, done) {
      this.cropFile = file
      this.cropDoneCallback = done
      this.$_goToStep('cropping')
    },

    $_onDropFileAdded() {
      if (this.dropzone.dropzone.files.length > 1) {
        this.dropzone.removeFile(this.dropzone.dropzone.files[0])
      }
    },

    $_onDropError(file, message) {
      this.error = message
      this.$_goToStep('error')
    },

    $_onDropSending() {
      this.$_goToStep('uploading')
    },

    $_onDropComplete() {
      this.progress = 0.0
    },

    $_onDropProgress(progress) {
      this.progress = progress / 100
    },

    $_onDropSuccess(file, response) {
      this.imageUrl = response.url
      this.imageData = JSON.stringify(response.data)
      this.$emit('image-uploaded', this.imageUrl, this.imageData)
      this.$_goToStep(this.processingCallback ? 'processing' : 'uploaded')
    },

    $_onCanceled() {
      this.dropzone.removeAllFiles(true)
      this.$_goToStep('initial')
    },

    $_onCropSuccess(blob) {
      this.cropDoneCallback(blob)
    },

    $_onCropError(message) {
      this.error = message
      this.$_goToStep('error')
    },

    $_onProcessed() {
      this.$_goToStep('uploaded')
    },
  },
}
</script>
