<template>
  <div ref="canvas" class="relative inline-block" @mousemove="$_onMouseMove">
    <GlobalEvents
      target="window"
      @resize="$_setDimensions"
      @load="$_setDimensions"
      @mouseup="$_onMouseUp"
      @mousedown.capture="$_onClickOutside"
    />

    <img
      ref="img"
      :srcset="imageSrcset"
      :src="imageUrl"
      class="rounded shadow select-none"
      draggable="false"
      @mousedown.left="$_onMouseDown"
      @load="$_setDimensions"
    />

    <div
      v-if="painting"
      ref="box"
      class="look-box border-dashed"
      :style="paintedBoxStyles"
    />

    <slot></slot>
  </div>
</template>

<script>
import defaultLookTag from '../../mixins/defaultLookTag'

export default {
  mixins: [defaultLookTag],

  props: {
    isEditable: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    imageSrcset: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      dimensions: {
        w: 0,
        h: 0,
      },
      box: {
        startX: 0,
        startY: 0,
        width: 0,
        height: 0,
        translateX: 0,
        translateY: 0,
      },
      painting: false,
    }
  },

  computed: {
    img() {
      return this.$refs.img
    },

    lookTagData() {
      const top = this.box.startY + this.box.translateY
      const left = this.box.startX + this.box.translateX

      const data = {
        x1: left / this.dimensions.w,
        y1: top / this.dimensions.h,
        x2: (left + this.box.width) / this.dimensions.w,
        y2: (top + this.box.height) / this.dimensions.h,
      }

      return this.$_defaultLookTag(data)
    },

    paintedBoxStyles() {
      return {
        left: `${this.box.startX}px`,
        top: `${this.box.startY}px`,
        width: `${this.box.width}px`,
        height: `${this.box.height}px`,
        transform: `translate(${this.box.translateX}px, ${this.box.translateY}px)`,
      }
    },

    imagePositionFromTop() {
      const rect = this.img.getBoundingClientRect()
      return rect.top + window.scrollY
    },

    imagePositionFromLeft() {
      const rect = this.img.getBoundingClientRect()
      return rect.left + window.scrollX
    },

    mergeSourceUuid() {
      return this.$store.state.lookTagMergeSourceUuid
    },
  },

  methods: {
    $_setDimensions() {
      const rect = this.img.getBoundingClientRect()
      this.dimensions.w = rect.width
      this.dimensions.h = rect.height
    },

    $_onMouseDown(event) {
      if (!this.isEditable) return
      this.painting = true

      this.box.startX = event.pageX - this.imagePositionFromLeft
      this.box.startY = event.pageY - this.imagePositionFromTop
    },

    $_onMouseMove(event) {
      if (!this.painting || !this.isEditable) return

      const wpos = event.pageX - this.imagePositionFromLeft
      const hpos = event.pageY - this.imagePositionFromTop

      if (wpos >= this.box.startX) {
        this.box.width = wpos - this.box.startX
      } else {
        this.box.width = this.box.startX - wpos
        this.box.translateX = wpos - this.box.startX
      }

      if (hpos >= this.box.startY) {
        this.box.height = hpos - this.box.startY
      } else {
        this.box.height = this.box.startY - hpos
        this.box.translateY = hpos - this.box.startY
      }
    },

    $_onMouseUp() {
      if (!this.painting || !this.isEditable) return
      this.painting = false

      // Create look tag
      this.$store.commit('addLookTag', this.lookTagData)

      // Select and merge with source if in merging mode
      if (this.mergeSourceUuid) {
        this.$store.commit('selectLookTag', this.mergeSourceUuid)
        this.$store.commit('mergeLookTags', this.lookTagData.uuid)
      } else {
        this.$store.commit('selectLookTag', this.lookTagData.uuid)
      }

      // reset to default values
      this.box.startX = 0
      this.box.startY = 0
      this.box.translateX = 0
      this.box.translateY = 0
      this.box.width = 0
      this.box.height = 0
    },

    $_onClickOutside(event) {
      const notInCanvas = this.$refs.canvas.contains(event.target) === false
      const notInLegend = !event.target.closest('[data-look-tag-in-legend]')

      if (notInCanvas && notInLegend) {
        this.$store.commit('selectLookTag', null)
      }
    },
  },
}
</script>
