<template>
  <div class="card mb-6">
    <div class="card-body">
      <div class="form-group">
        <label for="look_description" class="form-label">
          {{ $t('activerecord.attributes.look.description') }}
        </label>
        <textarea
          id="look_description"
          v-model="inputValue"
          name="look[description]"
          rows="2"
          class="form-field"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      inputValue: this.value,
    }
  },
}
</script>
