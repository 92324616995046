<template>
  <div class="flex items-center">
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span class="mr-2" v-html="option.avatar_html" />
    <div class="flex-grow truncate">
      {{ option.full_name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
      required: true,
    },
  },
}
</script>
