<template>
  <input type="hidden" :name="name" :value="inputValue" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean, Array],
      required: false,
      default: null,
    },
  },

  computed: {
    inputValue() {
      return Array.isArray(this.value) ? JSON.stringify(this.value) : this.value
    },
  },
}
</script>
