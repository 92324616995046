<template>
  <div class="text-center p-5 bg-white rounded">
    <Progress :progress="progress"></Progress>
    <a
      href="#"
      class="mt-4 btn btn-sm btn-outline-red"
      @click.prevent="$_onCancel"
    >
      {{ $t('shared.cancel') }}
    </a>
  </div>
</template>

<script>
import Progress from './Progress'

export default {
  components: { Progress },

  props: {
    progress: {
      type: Number,
      required: true,
    },
  },

  methods: {
    $_onCancel() {
      this.$emit('canceled')
    },
  },
}
</script>
