<template>
  <div class="text-center relative">
    <vue-cropper
      ref="cropper"
      :src="fileSrc"
      :img-style="{ width: '100%' }"
      :view-mode="2"
      :rotatable="false"
      :movable="false"
      :scalable="false"
      :zoomable="false"
      :auto-crop-area="0.9"
      :aspect-ratio="aspectRatio"
      :cropstart="$_onCropStart"
      :cropend="$_onCropEnd"
      :crop="$_onCrop"
      drag-mode="none"
    />
    <div>
      <a href="#" class="btn btn-gray mx-1 mt-4" @click.prevent="$_onCropReset">
        <i class="fas fa-undo mr-1" />
        {{ $t('shared.reset') }}
      </a>
      <a
        href="#"
        class="btn btn-blue mx-1 mt-4"
        @click.prevent="$_onCropConfirm"
      >
        <i class="fas fa-check mr-1" />
        {{ $t('shared.crop') }}
      </a>
    </div>
    <div>
      <a
        href="#"
        class="btn btn-sm btn-outline-blue mt-4"
        @click.prevent="$_onCancel"
      >
        {{ $t('image_uploader.change') }}
      </a>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import VueCropper from 'vue-cropperjs'

export default {
  components: {
    VueCropper,
  },

  props: {
    file: {
      type: [File, Blob],
      required: true,
    },
    aspectRatio: {
      type: Number,
      required: true,
    },
    minWidth: {
      type: Number,
      required: true,
    },
    minHeight: {
      type: Number,
      required: true,
    },
    maxWidth: {
      type: Number,
      required: true,
    },
    maxHeight: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      action: null,
    }
  },

  computed: {
    fileSrc() {
      return URL.createObjectURL(this.file)
    },

    cropper() {
      return this.$refs.cropper
    },

    isSmallImage() {
      const data = this.cropper.getImageData()
      return (
        data.naturalWidth < this.minWidth || data.naturalHeight < this.minHeight
      )
    },

    isTopAction() {
      const topActions = ['w', 'nw', 'n', 'ne', 'e']
      return topActions.includes(this.action)
    },

    isTopHalfAction() {
      const topHalfActions = ['w', 'e']
      return topHalfActions.includes(this.action)
    },

    isLeftAction() {
      const leftActions = ['n', 'nw', 'w', 'sw', 's']
      return leftActions.includes(this.action)
    },

    isLeftHalfAction() {
      const leftHalfActions = ['n', 's']
      return leftHalfActions.includes(this.action)
    },
  },

  methods: {
    $_onCropReset() {
      this.cropper.reset()
    },

    $_onCropStart(event) {
      this.action = event.detail.action
    },

    $_onCropEnd() {
      this.action = null
    },

    $_onCrop() {
      if (this.isSmallImage) {
        this.$_fileTooSmallError()
      } else {
        this.$_ensureMinCropSize()
      }
    },

    $_ensureMinCropSize: _.throttle(function () {
      const data = this.cropper.getData()

      const width = Math.ceil(data.width)
      const height = Math.ceil(data.height)

      if (width < this.minWidth || height < this.minHeight) {
        const left = Math.floor(data.x)
        const top = Math.floor(data.y)

        let newLeft = left
        let newTop = top

        if (this.isLeftAction) {
          let diff = width - this.minWidth
          if (this.isLeftHalfAction) {
            diff = Math.round(diff / 2)
          }
          newLeft += diff
        }

        if (this.isTopAction) {
          let diff = height - this.minHeight
          if (this.isTopHalfAction) {
            diff = Math.round(diff / 2)
          }
          newTop += diff
        }

        this.cropper.setData({
          x: Math.max(0, newLeft),
          y: Math.max(0, newTop),
          width: this.minWidth,
          height: this.minHeight,
        })
      }
    }, 1),

    $_fileTooSmallError() {
      const minDims = [this.minWidth, this.minHeight].join('x')
      const message = this.$t('image_uploader.file_too_small', {
        dimensions: minDims,
      })
      this.$emit('error', message)
    },

    $_onCancel() {
      this.$emit('canceled')
    },

    $_onCropConfirm() {
      const croppedCanvas = this.cropper.getCroppedCanvas({
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
      })

      croppedCanvas.toBlob(blob => this.$emit('cropped', blob))
    },
  },
}
</script>
