<template>
  <div ref="wrapper" class="inline-block text-left">
    <a
      ref="toggler"
      href="#"
      class="text-xl"
      :class="isWishlisted ? 'text-blue-600' : 'text-gray-600'"
      @click.prevent="$_toggleOpen"
    >
      <i
        class="fa-bookmark"
        :class="isWishlisted ? 'fas' : 'far'"
        :title="$t('wishlist_button.add_to_wishlist')"
      ></i>
    </a>

    <div v-show="open" ref="card" class="card w-64">
      <div class="card-body">
        <template v-if="loading">
          <i class="fas fa-circle-notch fa-spin text-gray-600" />
        </template>

        <template v-else>
          <SelectedLists
            :target-id="targetId"
            :target-type="targetType"
            :wishlists="checkedWishlists"
            @wishlists-updated="$_updateWishlists"
          />

          <ListForm
            :target-id="targetId"
            :target-type="targetType"
            :wishlists="uncheckedWishlists"
            @wishlists-updated="$_updateWishlists"
          />
        </template>
      </div>
    </div>

    <GlobalEvents target="window" @click="$_clickOutside" />
  </div>
</template>

<script>
import SelectedLists from './SelectedLists'
import ListForm from './ListForm'

import { createPopper } from '@popperjs/core'

export default {
  components: {
    SelectedLists,
    ListForm,
  },

  props: {
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    wishlisted: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      open: false,
      loading: false,
      popper: null,
      wishlists: [],
      isWishlisted: this.wishlisted,
    }
  },

  computed: {
    checkedWishlists() {
      return this.wishlists.filter(wishlist => wishlist.checked)
    },

    uncheckedWishlists() {
      return this.wishlists.filter(wishlist => !wishlist.checked)
    },
  },

  destroyed() {
    this.$_destroyPopper()
  },

  methods: {
    $_toggleOpen() {
      this.$_destroyPopper()
      this.open = !this.open

      if (this.open) {
        this.$_fetchWishlists()
        this.$_createPopper()
      }
    },

    $_createPopper() {
      this.popper = createPopper(this.$refs.toggler, this.$refs.card, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      })
    },

    $_destroyPopper() {
      if (this.popper) {
        this.popper.destroy()
        this.popper = null
      }
    },

    $_clickOutside(event) {
      if (this.$refs.wrapper.contains(event.target) === false) {
        this.$_destroyPopper()
        this.open = false
      }
    },

    $_fetchWishlists() {
      this.loading = true

      this.$http
        .get('/js/wishlist_items', {
          params: {
            target_id: this.targetId,
            target_type: this.targetType,
          },
        })
        .then(response => {
          this.$_updateWishlists(response.data)
          this.loading = false
        })
        .catch(error => console.error(error))
    },

    $_updateWishlists(data) {
      this.wishlists = data.wishlists
      this.isWishlisted = this.checkedWishlists.length > 0
    },
  },
}
</script>
