import { Controller } from 'stimulus'

import Vue from 'vue/dist/vue.esm'

import TurbolinksAdapter from 'vue-turbolinks'
Vue.use(TurbolinksAdapter)

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import I18n from '../plugins/i18n.js.erb'
Vue.use(I18n)

import GlobalEvents from 'vue-global-events'
Vue.component('GlobalEvents', GlobalEvents)

import ImageUploader from '../components/ImageUploader'
import LookImage from '../components/LookImage'
import LookForm from '../components/LookForm'
import LookTags from '../components/LookTags'
import ProductAttributes from '../components/ProductAttributes'
import LikeButton from '../components/LikeButton'
import FollowButton from '../components/FollowButton'
import WishlistButton from '../components/WishlistButton'

import makeStore from '../helpers/makeStore'

Vue.config.productionTip = false

export default class extends Controller {
  static targets = ['application']

  connect() {
    new Vue({
      el: this.applicationTarget,
      store: makeStore(),
      components: {
        ImageUploader,
        LookImage,
        LookForm,
        LookTags,
        ProductAttributes,
        LikeButton,
        FollowButton,
        WishlistButton,
      },
    })
  }
}
