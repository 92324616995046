<template>
  <div>
    <div v-if="editable && isSelected" class="mb-2">
      <ProfileSelect :profile="profile" @select="$_onProfileSelect" />

      <div v-if="profile" class="mt-2">
        <ProductSelect
          :product="product"
          :profile-id="profile.id"
          @select="$_onProductSelect"
        />
      </div>
    </div>

    <div v-if="product" class="flex flex-col sm:flex-row -mx-2">
      <div
        v-if="product.thumbnail_image_url"
        class="sm:w-1/4 sm:flex-shrink-0 mb-4 px-2"
      >
        <img
          :src="product.thumbnail_image_url"
          :srcset="product.thumbnail_image_srcset"
          class="rounded"
        />
      </div>

      <div class="flex-grow min-w-0 break-words px-2">
        <div class="font-bold text-lg mb-1">
          {{ product.title }}
        </div>

        <TagUrl v-if="product.url" :url="product.url" :editable="editable" />

        <div
          v-for="(attribute, index) of productAttributes"
          :key="attribute.name + index"
        >
          <strong>{{ attribute.name }}:</strong> {{ attribute.value }}
        </div>

        <div v-if="profile" class="mt-2 text-right">
          <ProfileLink :profile="profile" :editable="editable" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TagUrl from './TagUrl'
import ProfileSelect from './ProfileSelect'
import ProductSelect from './ProductSelect'
import ProfileLink from './ProfileLink'

export default {
  components: { TagUrl, ProfileSelect, ProductSelect, ProfileLink },

  props: {
    uuid: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      profile: null,
    }
  },

  computed: {
    lookTag() {
      return this.$store.getters.lookTagByUuid(this.uuid)
    },

    product() {
      return this.$store.getters.productById(this.lookTag.product_id)
    },

    productAttributes() {
      const attributes = []

      if (this.product?.attributes_data) {
        this.product.attributes_data.forEach(attribute => {
          attributes.push({ name: attribute.name, value: attribute.value })
        })
      }

      return attributes
    },

    isSelected() {
      return this.$store.state.selectedLookTagUuid === this.uuid
    },
  },

  mounted() {
    if (this.product?.user_id) {
      const profileId = this.product.user_id
      this.profile = this.$store.getters.profileById(profileId)
    } else if (this.$store.state.currentUserId) {
      const profileId = this.$store.state.currentUserId
      this.profile = this.$store.getters.profileById(profileId)
    }
  },

  methods: {
    $_onProfileSelect(profile) {
      this.profile = profile
      if (profile.id !== this.product?.user_id) {
        this.$store.commit('addProfile', profile)
        this.$_updateLookTagProductId(null)
      }
    },

    $_onProductSelect(product) {
      this.$store.commit('addProduct', product)
      this.$_updateLookTagProductId(product.id)
    },

    $_updateLookTagProductId(productId) {
      this.$store.commit('updateLookTag', {
        uuid: this.uuid,
        product_id: productId,
      })
    },
  },
}
</script>
