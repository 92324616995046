import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import VuexTurbolinks from 'vuex-turbolinks'

Vue.use(Vuex)

function lookTagChildIndexesByUuid(state, uuid) {
  return state.lookTags.reduce(
    (a, e, i) => (e.parent_uuid === uuid ? [...a, i] : a),
    []
  )
}

export default function makeStore() {
  return new Vuex.Store({
    state: {
      currentUserId: null,
      currentUserType: null,
      lookTags: [],
      products: [],
      profiles: [],
      likedLookTagIds: [],
      wishlistedLookTagIds: [],
      highlightedLookTagUuid: null,
      selectedLookTagUuid: null,
      lookTagMergeSourceUuid: null,
    },

    getters: {
      lookTagByUuid: state => uuid => {
        return state.lookTags.find(item => item.uuid === uuid)
      },

      lookTagPositionByUuid: (state, getters) => uuid => {
        const tag = getters.lookTagByUuid(uuid)
        const targetUuid = tag.parent_uuid || tag.uuid

        const index = state.lookTags
          .filter(item => item.confirmed && !item._destroy && !item.parent_uuid)
          .findIndex(item => item.uuid === targetUuid)

        return index >= 0 ? index + 1 : null
      },

      lookTagAttributeByUuid: (state, getters) => (lookTagUuid, uuid) => {
        const tag = getters.lookTagByUuid(lookTagUuid)
        return tag.attributes_data.find(item => item.uuid === uuid)
      },

      lookTagChildrenByUuid: state => uuid => {
        const childIndexes = lookTagChildIndexesByUuid(state, uuid)
        return childIndexes.map(index => state.lookTags[parseInt(index)])
      },

      productById: state => id => {
        return state.products.find(product => product.id === id)
      },

      profileById: state => id => {
        return state.profiles.find(profile => profile.id === id)
      },
    },

    mutations: {
      setCurrentUserId(state, id) {
        state.currentUserId = id
      },

      setCurrentUserType(state, userType) {
        state.currentUserType = userType
      },

      addLookTag(state, lookTag) {
        state.lookTags.push(lookTag)
      },

      cleanLookTags(state) {
        state.lookTags = []
      },

      updateLookTag(state, newVals) {
        const index = state.lookTags.findIndex(
          item => item.uuid === newVals.uuid
        )
        const tag = {
          ...state.lookTags[parseInt(index)],
          ...newVals,
        }
        state.lookTags.splice(index, 1, tag)
      },

      confirmLookTag(state, uuid) {
        const index = state.lookTags.findIndex(item => item.uuid === uuid)
        const tag = {
          ...state.lookTags[parseInt(index)],
          confirmed: true,
        }
        state.lookTags.splice(index, 1, tag)

        // Confirm children
        const childIndexes = lookTagChildIndexesByUuid(state, uuid)
        childIndexes.forEach(childIndex => {
          const child = {
            ...state.lookTags[parseInt(childIndex)],
            confirmed: true,
          }
          state.lookTags.splice(childIndex, 1, child)
        })
      },

      removeLookTag(state, uuid) {
        const index = state.lookTags.findIndex(item => item.uuid === uuid)
        const tag = {
          ...state.lookTags[parseInt(index)],
          _destroy: true,
        }
        state.lookTags.splice(index, 1, tag)

        // Remove children
        const childIndexes = lookTagChildIndexesByUuid(state, uuid)
        childIndexes.forEach(childIndex => {
          const child = {
            ...state.lookTags[parseInt(childIndex)],
            _destroy: true,
          }
          state.lookTags.splice(childIndex, 1, child)
        })

        // Check correct references
        if (state.lookTagMergeSourceUuid === uuid) {
          state.lookTagMergeSourceUuid = null
        }
        if (state.selectedLookTagUuid === uuid) {
          state.selectedLookTagUuid = null
        }
        if (state.highlightedLookTagUuid === uuid) {
          state.highlightedLookTagUuid = null
        }
      },

      addLookTagAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        tag.attributes_data.push(attribute.data)
      },

      updateLookTagAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        const index = tag.attributes_data.findIndex(
          item => item.uuid === attribute.uuid
        )
        const data = {
          ...tag.attributes_data[parseInt(index)],
          ...attribute.data,
        }
        tag.attributes_data.splice(index, 1, data)
      },

      removeLookTagAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        tag.attributes_data = tag.attributes_data.filter(
          item => item.uuid !== attribute.uuid
        )
      },

      updateLookTagProduct(state, product) {
        const tag = state.lookTags.find(
          item => item.uuid === product.lookTagUuid
        )
        tag.product_attributes = {
          ...tag.product_attributes,
          ...product.newValues,
        }
      },

      addLookTagProductAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        const product = tag.product_attributes
        product.attributes_data.push(attribute.data)
      },

      updateLookTagProductAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        const product = tag.product_attributes
        const index = product.attributes_data.findIndex(
          item => item.uuid === attribute.uuid
        )
        const data = {
          ...product.attributes_data[parseInt(index)],
          ...attribute.data,
        }
        product.attributes_data.splice(index, 1, data)
      },

      removeLookTagProductAttribute(state, attribute) {
        const tag = state.lookTags.find(
          item => item.uuid === attribute.lookTagUuid
        )
        const product = tag.product_attributes
        product.attributes_data = product.attributes_data.filter(
          item => item.uuid !== attribute.uuid
        )
      },

      highlightLookTag(state, uuid) {
        state.highlightedLookTagUuid = uuid
      },

      selectLookTag(state, uuid) {
        state.selectedLookTagUuid = uuid
      },

      setLookTagMergeSource(state, uuid) {
        state.lookTagMergeSourceUuid = uuid
      },

      mergeLookTags(state, targetUuid) {
        // Find final source
        let source = state.lookTags.find(
          item => item.uuid === state.lookTagMergeSourceUuid
        )
        if (source.parent_uuid) {
          source = state.lookTags.find(item => item.uuid === source.parent_uuid)
        }

        // Build new common attributes
        const common_attributes = {
          parent_id: source.id,
          parent_uuid: source.uuid,
          confirmed: true,
          type: source.type,
          product_id: source.product_id,
          title: null,
          url: null,
          attributes_data: [],
          product_attributes: {
            title: null,
            url: null,
            image: null,
            thumbnail_image_url: null,
            thumbnail_image_srcset: null,
            attributes_data: [],
          },
        }

        // Point target to source
        const targetIndex = state.lookTags.findIndex(
          item => item.uuid === targetUuid
        )
        const target = {
          ...state.lookTags[parseInt(targetIndex)],
          ...common_attributes,
        }
        state.lookTags.splice(targetIndex, 1, target)

        // Point target's children to target
        const childIndexes = lookTagChildIndexesByUuid(state, target.uuid)
        childIndexes.forEach(childIndex => {
          const child = {
            ...state.lookTags[parseInt(childIndex)],
            ...common_attributes,
          }
          state.lookTags.splice(childIndex, 1, child)
        })

        // End merging process
        state.lookTagMergeSourceUuid = null
      },

      splitLookTags(state, uuid) {
        const parent = state.lookTags.find(item => item.uuid === uuid)
        const childIndexes = lookTagChildIndexesByUuid(state, uuid)
        childIndexes.forEach(childIndex => {
          const child = {
            ...state.lookTags[parseInt(childIndex)],
            parent_id: null,
            parent_uuid: null,
            title: parent.title,
            attributes_data: [...parent.attributes_data],
            product_attributes: {
              ...parent.product_attributes,
              attributes_data: [...parent.product_attributes.attributes_data],
            },
          }
          state.lookTags.splice(childIndex, 1, child)
        })
      },

      addProduct(state, product) {
        const oldProduct = state.products.find(item => item.id === product.id)
        if (!oldProduct) state.products.push(product)
      },

      addProfile(state, profile) {
        const oldProfile = state.profiles.find(item => item.id === profile.id)
        if (!oldProfile) state.profiles.push(profile)
      },

      addLikedLookTagIds(state, lookTagIds) {
        state.likedLookTagIds = [...state.likedLookTagIds, ...lookTagIds]
      },

      addWishlistedLookTagIds(state, lookTagIds) {
        state.wishlistedLookTagIds = [
          ...state.wishlistedLookTagIds,
          ...lookTagIds,
        ]
      },
    },

    strict: process.env.NODE_ENV !== 'production',

    plugins: [VuexTurbolinks],
  })
}
