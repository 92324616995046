<template>
  <div class="text-center">
    <a
      href="#"
      class="block rounded bg-gray-200 border-2 border-gray-400 hover:border-blue-500 border-dashed p-5"
      @click.prevent="$_openFileSelector"
    >
      <i class="fas fa-cloud-upload-alt text-5xl text-gray-400" />
      <div class="text-lg text-gray-700 leading-tight mt-1">
        {{ $t('image_uploader.drop_image_here_or_click') }}
      </div>
    </a>

    <div class="mt-2">
      <div v-if="openUrlForm" class="flex items-center">
        <input
          ref="imageUrlInput"
          v-model="imageUrl"
          type="text"
          placeholder="https://..."
          class="form-field flex-grow m-0"
        />
        <div class="w-auto ml-1">
          <button
            class="btn btn-blue"
            :title="$t('shared.add')"
            :disabled="downloading"
            @click.prevent="$_useImageUrl"
          >
            <i v-if="downloading" class="fas fa-spinner fa-spin" />
            <i v-else class="fas fa-check" />
          </button>
        </div>
      </div>
      <a v-else href="#" class="text-link" @click.prevent="$_openUrlForm">
        {{ $t('image_uploader.provide_url') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    maxSize: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      openUrlForm: false,
      imageUrl: null,
      downloading: false,
    }
  },

  methods: {
    $_openFileSelector() {
      this.openUrlForm = false
      this.$emit('open-file-selector')
    },

    $_openUrlForm() {
      this.openUrlForm = true
      this.$nextTick(() => this.$refs.imageUrlInput.focus())
    },

    $_useImageUrl() {
      if (this.imageUrl) {
        this.downloading = true

        this.$http({
          url: this.imageUrl,
          method: 'GET',
          responseType: 'blob',
          maxContentLength: this.maxSize,
        })
          .then(response => {
            this.$emit('url-blob', response.data)
          })
          .catch(error => {
            console.error(error)
            const message = this.$t('image_uploader.url_fetching_error')
            this.$emit('url-error', message)
          })
      }
    },
  },
}
</script>
