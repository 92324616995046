<template>
  <div
    ref="box"
    class="look-box"
    :class="boxClasses"
    :style="positions"
    @mouseover="$_onMouseOver"
    @mouseleave="$_onMouseLeave"
    @click.prevent="$_onClick"
  >
    <div v-if="isConfirmed" class="look-box-number" :class="boxNumberClasses">
      {{ lookTagPosition }}
    </div>
  </div>
</template>

<script>
import interact from 'interactjs'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    uuid: {
      type: String,
      required: true,
    },
    parentUuid: {
      type: String,
      default: null,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isConfirmed: {
      type: Boolean,
      required: true,
    },

    // Start coordinates (percentage of canvas dimensions).
    x1: {
      type: Number,
      default: 0,
    },
    y1: {
      type: Number,
      default: 0,
    },

    // End coordinates (percentage of canvas dimensions).
    x2: {
      type: Number,
      default: 0,
    },
    y2: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      interact: null,
    }
  },

  computed: {
    lookTagPosition() {
      return this.$store.getters.lookTagPositionByUuid(this.uuid)
    },

    box() {
      // Turn start / end percentages into x, y, width, height in pixels.
      return {
        x: this.$_percentWidthToPx(this.x1 * 100),
        y: this.$_percentHeightToPx(this.y1 * 100),
        w: this.$_percentWidthToPx(this.x2 * 100 - this.x1 * 100),
        h: this.$_percentHeightToPx(this.y2 * 100 - this.y1 * 100),
      }
    },

    coordinates() {
      const left = this.box.x + this.x
      const top = this.box.y + this.y

      return {
        id: this.id,
        uuid: this.uuid,
        x1: left / this.$parent.dimensions.w,
        y1: top / this.$parent.dimensions.h,
        x2: (left + this.w) / this.$parent.dimensions.w,
        y2: (top + this.h) / this.$parent.dimensions.h,
      }
    },

    positions() {
      return {
        left: `${this.box.x}px`,
        top: `${this.box.y}px`,
        width: `${this.box.w}px`,
        height: `${this.box.h}px`,
        touchAction: 'none',
      }
    },

    boxClasses() {
      return {
        'border-dashed': !this.isConfirmed,
        'border-red-500': this.showAsSelected,
        'border-teal-400': this.isMergeTarget,
      }
    },

    boxNumberClasses() {
      return {
        'bg-red-500': this.showAsSelected,
      }
    },

    showAsSelected() {
      const uuid = this.$store.state.selectedLookTagUuid
      return uuid && (uuid === this.uuid || uuid === this.parentUuid)
    },

    mergeSourceUuid() {
      return this.$store.state.lookTagMergeSourceUuid
    },

    isMergeTarget() {
      return (
        this.isEditable &&
        this.mergeSourceUuid &&
        this.mergeSourceUuid !== this.uuid &&
        this.mergeSourceUuid !== this.parentUuid
      )
    },
  },

  mounted() {
    if (!this.isEditable) return

    this.interact = interact(this.$refs['box'])

    this.$_resizable()
    this.$_draggable()
  },

  methods: {
    $_percentWidthToPx(percent) {
      return Math.floor((this.$parent.dimensions.w / 100) * percent)
    },

    $_percentHeightToPx(percent) {
      return Math.floor((this.$parent.dimensions.h / 100) * percent)
    },

    $_onMouseOver() {
      this.$store.commit('highlightLookTag', this.parentUuid || this.uuid)
    },

    $_onMouseLeave() {
      this.$store.commit('highlightLookTag', null)
    },

    $_onClick() {
      if (this.isMergeTarget) {
        this.$store.commit('selectLookTag', this.mergeSourceUuid)
        this.$store.commit('mergeLookTags', this.parentUuid || this.uuid)
      } else if (this.isEditable) {
        this.$store.commit('selectLookTag', this.parentUuid || this.uuid)
      }
    },

    $_onInteractFinish(event) {
      this.$store.commit('updateLookTag', this.coordinates)

      // Reset positions
      this.x = 0
      this.y = 0
      event.target.style.transform = null
    },

    $_moveBox(target) {
      target.style.transform = `translate(${this.x}px, ${this.y}px)`
    },

    $_draggable() {
      this.interact.draggable({
        onmove: event => {
          const { target, dx, dy, rect } = event
          this.x += dx
          this.y += dy
          this.w = rect.width
          this.h = rect.height

          this.$_moveBox(target)
        },

        onend: this.$_onInteractFinish,

        cursorChecker: action => {
          switch (action.axis) {
            case 'x':
              return 'ew-resize'
            case 'y':
              return 'ns-resize'
            default:
              return this.isMergeTarget ? 'pointer' : 'move'
          }
        },

        modifiers: [
          interact.modifiers.restrictRect({
            restriction: this.$parent.img,
          }),
        ],
      })
    },

    $_resizable() {
      this.interact.resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        invert: 'reposition',

        modifiers: [
          interact.modifiers.restrictEdges({
            outer: 'parent',
          }),
        ],

        onmove: event => {
          const { target, rect, deltaRect } = event

          target.style.width = `${rect.width}px`
          target.style.height = `${rect.height}px`

          this.x += deltaRect.left
          this.y += deltaRect.top
          this.w = rect.width
          this.h = rect.height

          this.$_moveBox(target)
        },

        onend: this.$_onInteractFinish,
      })
    },
  },
}
</script>
