<template>
  <div v-if="showEditForm" class="flex items-center mb-2 -mx-2">
    <div class="w-1/2 px-2">
      <Autocomplete
        ref="autocompleteName"
        :value="name"
        :input-id="inputNameId"
        query-url="/js/autocomplete/entity_attributes"
        @autocomplete-input="$_emitUpdateName"
        @autocomplete-enter="$_focusValueIfName"
      />
    </div>
    <div class="flex-grow px-2">
      <Autocomplete
        ref="autocompleteValue"
        :value="value"
        :input-id="inputValueId"
        :input-disabled="inputValueDisabled"
        :query-params="valueQueryParams"
        query-url="/js/autocomplete/entity_values"
        @autocomplete-input="$_emitUpdateValue"
        @autocomplete-enter="$_emitEnterIfValue"
      />
    </div>
    <div class="w-auto px-2">
      <a
        href="#"
        class="text-gray-600 hover:text-red-700"
        :title="$t('entity_attributes.remove_attribute')"
        @click.prevent="$_emitRemoveAttribute"
      >
        <i class="fas fa-times"></i>
      </a>
    </div>
  </div>

  <div v-else-if="notEmpty">
    <strong>{{ name || '-' }}:</strong> {{ value || '-' }}
  </div>
</template>

<script>
import Autocomplete from './Autocomplete'

export default {
  components: {
    Autocomplete,
  },

  props: {
    uuid: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    focused: {
      type: Boolean,
      default: false,
    },
    showEditForm: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      inputNameId: `${this.uuid}-name-autocomplete`,
      inputValueId: `${this.uuid}-value-autocomplete`,
    }
  },

  computed: {
    valueQueryParams() {
      return { attribute: this.name }
    },

    inputValueDisabled() {
      return this.name.trim().length === 0
    },

    notEmpty() {
      return this.name.length > 0
    },
  },

  watch: {
    focused: function (newFocused, oldFocused) {
      if (newFocused && !oldFocused) {
        this.$_emitResetFocus()
        this.$_focusName()
      }
    },
  },

  mounted() {
    if (this.focused) {
      this.$_emitResetFocus()
      this.$nextTick(this.$_focusName)
    }
  },

  methods: {
    $_focusName() {
      this.$refs.autocompleteName.$focus()
    },

    $_focusValueIfName() {
      if (this.name.trim().length > 0) {
        this.$refs.autocompleteValue.$focus()
      }
    },

    $_emitEnterIfValue() {
      if (this.value.trim().length > 0) {
        this.$emit('value-enter', this.uuid)
      }
    },

    $_emitRemoveAttribute() {
      this.$emit('remove-attribute', this.uuid)
    },

    $_emitUpdateName(newName) {
      this.$emit('update-name', this.uuid, newName)
    },

    $_emitUpdateValue(newValue) {
      this.$emit('update-value', this.uuid, newValue)
    },

    $_emitResetFocus() {
      this.$emit('reset-focus', this.uuid)
    },
  },
}
</script>
