<template>
  <form v-if="wishlists.length > 0" class="flex" @submit.prevent="$_submit">
    <select
      id="wishlist_item_wishlist_id"
      v-model="wishlistId"
      name="wishlist_item[wishlist_id]"
      class="form-field form-field-select flex-grow"
    >
      <option
        v-for="wishlist in wishlists"
        :key="wishlist.id"
        :value="wishlist.id"
      >
        {{ wishlist.title }}
      </option>
    </select>

    <div class="flex-shrink-0 ml-1">
      <button class="btn btn-blue" type="submit">
        {{ $t('shared.add') }}
      </button>
    </div>
  </form>
</template>

<script>
import authenticityToken from '../../mixins/authenticityToken'

export default {
  mixins: [authenticityToken],

  props: {
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    wishlists: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      wishlistId: null,
    }
  },

  watch: {
    wishlists: function (newLists) {
      if (newLists.length === 0) {
        this.wishlistId = null
      } else if (this.wishlistId === null) {
        this.wishlistId = newLists[0].id
      } else if (!newLists.some(list => list.id === this.wishlistId)) {
        this.wishlistId = newLists[0].id
      }
    },
  },

  mounted() {
    if (this.wishlists.length > 0) {
      this.wishlistId = this.wishlists[0].id
    }
  },

  methods: {
    $_submit() {
      this.$http
        .post(
          '/js/wishlist_items',
          {
            target_id: this.targetId,
            target_type: this.targetType,
            wishlist_id: this.wishlistId,
          },
          { headers: { 'X-CSRF-Token': this.$_authenticityToken() } }
        )
        .then(response => {
          this.$emit('wishlists-updated', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
