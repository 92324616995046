import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['wrapper', 'form', 'list']

  formSuccess(event) {
    const data = event.detail[0]

    if (data['form_html']) {
      this.replaceForm(data['scope'], data['form_html'])
    }

    if (data['comment_html']) {
      this.addComment(data['scope'], data['comment_html'])
    }
  }

  paginationSuccess(event) {
    const data = event.detail[0]

    if (data['html']) {
      this.replaceWrapper(data['html'])
    }
  }

  replaceForm(scope, html) {
    this.formTargets.forEach(formTarget => {
      if (formTarget.dataset.scope === scope) {
        formTarget.outerHTML = html
      }
    })
  }

  addComment(scope, html) {
    this.listTargets.forEach(listTarget => {
      if (listTarget.dataset.scope === scope) {
        const position = scope === 'main' ? 'afterbegin' : 'beforeend'
        listTarget.insertAdjacentHTML(position, html)
      }
    })
  }

  replaceWrapper(html) {
    this.wrapperTarget.scrollIntoView(true)
    this.wrapperTarget.outerHTML = html
  }
}
