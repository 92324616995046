<template>
  <div class="flex flex-col sm:flex-row -mx-2">
    <div
      v-if="editable && isSelected"
      class="sm:w-1/4 sm:flex-shrink-0 mb-4 px-2"
    >
      <ImageUploader
        :dropzone-id="uuid"
        upload-url="/uploads/product_images"
        :max-size="maxImageSize"
        :cached-image-url="product.thumbnail_image_url"
        :crop-enabled="false"
        @image-uploaded="$_onImageUploaded"
      />
    </div>
    <div
      v-else-if="product.thumbnail_image_url"
      class="sm:w-1/4 sm:flex-shrink-0 mb-4 px-2"
    >
      <img
        :src="product.thumbnail_image_url"
        :srcset="product.thumbnail_image_srcset"
        class="rounded"
      />
    </div>

    <div class="flex-grow min-w-0 break-words px-2">
      <div v-if="editable && isSelected">
        <div class="flex items-center mb-2">
          <div class="w-10 mr-2">
            <label class="form-label">
              {{ $t('activerecord.attributes.product.title') }}
            </label>
          </div>
          <div class="flex-grow">
            <input
              v-model="titleInputValue"
              type="text"
              class="form-field"
              @keydown.enter.prevent="$_onTitleInputEnter"
            />
          </div>
        </div>
        <div class="flex items-center mb-2">
          <div class="w-10 mr-2">
            <label class="form-label">
              {{ $t('activerecord.attributes.product.url') }}
            </label>
          </div>
          <div class="flex-grow">
            <input
              ref="urlInput"
              v-model="urlInputValue"
              type="text"
              class="form-field"
              @keydown.enter.prevent="$_onUrlInputEnter"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="font-bold text-lg mb-1">
          {{ product.title }}
        </div>
        <TagUrl v-if="product.url" :url="product.url" :editable="editable" />
      </div>

      <EntityAttributes
        :attributes-data="product.attributes_data"
        :show-edit-form="editable && isSelected"
        @update-name="$_updateAttributeName"
        @update-value="$_updateAttributeValue"
        @remove-attribute="$_removeAttribute"
        @add-empty-attribute="$_addEmptyAttribute"
        @value-enter="$_onAttributeValueEnter"
        @reset-focus="$_resetAttributeFocus"
      />
    </div>
  </div>
</template>

<script>
import TagUrl from './TagUrl'

import ImageUploader from '../ImageUploader'
import EntityAttributes from '../EntityAttributes'

import uuidv4 from '../../mixins/uuidv4'

export default {
  components: { TagUrl, ImageUploader, EntityAttributes },

  mixins: [uuidv4],

  props: {
    uuid: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    maxImageSize: {
      type: Number,
      default: null,
    },
  },

  computed: {
    lookTag() {
      return this.$store.getters.lookTagByUuid(this.uuid)
    },

    product() {
      return this.lookTag.product_attributes
    },

    isSelected() {
      return this.$store.state.selectedLookTagUuid === this.uuid
    },

    titleInputValue: {
      get() {
        return this.product.title
      },

      set(newValue) {
        this.$store.commit('updateLookTagProduct', {
          lookTagUuid: this.uuid,
          newValues: { title: newValue },
        })
      },
    },

    urlInputValue: {
      get() {
        return this.product.url
      },

      set(newValue) {
        this.$store.commit('updateLookTagProduct', {
          lookTagUuid: this.uuid,
          newValues: { url: newValue },
        })
      },
    },
  },

  methods: {
    $_onTitleInputEnter() {
      this.$refs.urlInput.focus()
    },

    $_onUrlInputEnter() {
      const productAttributes = this.product.attributes_data
      if (productAttributes.length > 0) {
        const nextUuid = productAttributes[0].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_onAttributeValueEnter(uuid) {
      const productAttributes = this.product.attributes_data
      const index = productAttributes.findIndex(item => item.uuid === uuid)
      if (index + 1 < productAttributes.length) {
        const nextUuid = productAttributes[index + 1].uuid
        this.$_focusAttribute(nextUuid)
      } else {
        this.$_addEmptyAttribute()
      }
    },

    $_addEmptyAttribute() {
      this.$store.commit('addLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        data: {
          uuid: this.$_uuidv4(),
          name: '',
          value: '',
          focused: true,
        },
      })
    },

    $_removeAttribute(uuid) {
      this.$store.commit('removeLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
      })
    },

    $_updateAttributeName(uuid, newName) {
      this.$store.commit('updateLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { name: newName },
      })
    },

    $_updateAttributeValue(uuid, newValue) {
      this.$store.commit('updateLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { value: newValue },
      })
    },

    $_focusAttribute(uuid) {
      this.$store.commit('updateLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { focused: true },
      })
    },

    $_resetAttributeFocus(uuid) {
      this.$store.commit('updateLookTagProductAttribute', {
        lookTagUuid: this.uuid,
        uuid: uuid,
        data: { focused: false },
      })
    },

    $_onImageUploaded(imageUrl, imageData) {
      this.$store.commit('updateLookTagProduct', {
        lookTagUuid: this.uuid,
        newValues: {
          image: imageData,
          thumbnail_image_url: imageUrl,
          thumbnail_image_srcset: null,
        },
      })
    },
  },
}
</script>
