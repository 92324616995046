<template>
  <div class="text-center">
    <slot name="uploaded" :image-url="imageUrl">
      <img :src="imageUrl" class="rounded" />
    </slot>

    <a
      href="#"
      class="btn btn-sm btn-outline-blue mt-4"
      @click.prevent="$_onCancel"
    >
      {{ $t('image_uploader.change') }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
  },

  methods: {
    $_onCancel() {
      this.$emit('canceled')
    },
  },
}
</script>
