<template>
  <div class="text-sm truncate mb-1">
    <i class="fas fa-external-link-alt text-gray-600 mr-1" />
    <template v-if="editable">
      {{ url }}
    </template>
    <a
      v-else
      :href="url"
      target="_blank"
      rel="noopener noreferrer"
      class="text-link"
    >
      {{ url }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
