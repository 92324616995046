<template>
  <div v-if="wishlists.length > 0" class="mb-4">
    <div v-for="wishlist in wishlists" :key="wishlist.id" class="flex">
      <div class="truncate flex-grow">
        {{ wishlist.title }}
      </div>

      <div v-if="wishlist.private">
        <i class="fas fa-lock text-gray-600 text-sm ml-2" />
      </div>

      <div class="flex-shrink-0">
        <button
          class="ml-2"
          :title="$t('shared.remove')"
          @click.prevent="$_delete(wishlist.id)"
        >
          <i class="fas fa-times text-red-600"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import authenticityToken from '../../mixins/authenticityToken'

export default {
  mixins: [authenticityToken],

  props: {
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    wishlists: {
      type: Array,
      required: true,
    },
  },

  methods: {
    $_delete(wishlistId) {
      this.$http
        .delete('/js/wishlist_items', {
          params: {
            target_id: this.targetId,
            target_type: this.targetType,
            wishlist_id: wishlistId,
          },
          headers: { 'X-CSRF-Token': this.$_authenticityToken() },
        })
        .then(response => {
          this.$emit('wishlists-updated', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
</script>
