import { Controller } from 'stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ['toggler', 'menu']

  initialize() {
    this.activeClass = 'active'
    this.popper = null
  }

  disconnect() {
    this.hide()
  }

  toggle(event) {
    event.preventDefault()

    this.destroyPopper()

    if (this.menuTarget.classList.toggle(this.activeClass)) {
      this.popper = createPopper(this.togglerTarget, this.menuTarget, {
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ],
      })
    }
  }

  outsideClick(event) {
    if (this.element.contains(event.target) === false) {
      this.hide()
    }
  }

  hide() {
    this.destroyPopper()
    this.menuTarget.classList.remove(this.activeClass)
  }

  destroyPopper() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
  }
}
