<template>
  <div
    class="card card-hover mb-4"
    :class="cardClasses"
    data-look-tag-in-legend="true"
    @mouseover="$_highlightLookTag"
    @mouseleave="$_dehighlightLookTag"
    @click.capture="$_selectLookTag"
  >
    <div class="card-body">
      <div class="flex">
        <div class="w-auto pr-3">
          <span href="#" class="badge-number" :class="badgeClasses">
            {{ lookTagPosition }}
          </span>
        </div>

        <div class="flex-grow min-w-0">
          <div v-if="!lookTag.type" class="mb-4">
            <button
              class="btn btn-sm btn-blue mx-2"
              @click.prevent="$_setLookTagType('product')"
            >
              <i class="fas fa-shopping-bag mr-1"></i>
              {{ $t('look_tags.edit.select_product_tag') }}
            </button>
            <span class="text-gray-500">/</span>
            <button
              class="btn btn-sm btn-blue mx-2"
              @click.prevent="$_setLookTagType('text')"
            >
              <i class="fas fa-align-left mr-1"></i>
              {{ $t('look_tags.edit.select_text_tag') }}
            </button>
          </div>
          <ProductTag
            v-else-if="lookTag.type === 'product'"
            :uuid="uuid"
            :editable="editable"
          />
          <NewProductTag
            v-else-if="lookTag.type === 'new_product'"
            :uuid="uuid"
            :editable="editable"
            :max-image-size="maxImageSize"
          />
          <TextTag
            v-else-if="lookTag.type === 'text'"
            :uuid="uuid"
            :editable="editable"
          />

          <div
            v-if="editable"
            class="flex flex-wrap justify-between text-sm mt-2"
          >
            <div>
              <button
                v-if="
                  newProductAllowed && isSelected && lookTag.type === 'product'
                "
                class="btn btn-sm btn-blue"
                @click.prevent="$_setLookTagType('new_product')"
              >
                <i class="fas fa-plus mr-1"></i>
                {{ $t('shared.new') }}
              </button>
            </div>

            <div>
              <template v-if="lookTag.type">
                <a
                  v-if="isSelected && hasChildren"
                  href="#"
                  class="text-gray-600 hover:text-blue-700 mr-4"
                  @click.prevent="$_splitChildren"
                >
                  <i class="fas fa-folder-minus mr-1"></i>
                  {{ $t('shared.split') }}
                </a>

                <a
                  v-if="isMergeSource"
                  href="#"
                  class="text-red-600 hover:text-red-700 mr-4"
                  @click.prevent="$_clearMergeSource"
                >
                  <i class="fas fa-times mr-1"></i>
                  {{ $t('shared.cancel') }}
                </a>
                <a
                  v-else-if="isSelected"
                  href="#"
                  class="text-gray-600 hover:text-blue-700 mr-4"
                  @click.prevent="$_setMergeSource"
                >
                  <i class="fas fa-folder-plus mr-1"></i>
                  {{ $t('shared.merge') }}
                </a>
              </template>

              <a
                v-if="isSelected"
                href="#"
                class="text-gray-600 hover:text-red-700"
                @click.prevent="$_removeLookTag"
              >
                <i class="fas fa-trash mr-1"></i>
                {{ $t('shared.remove') }}
              </a>

              <button
                v-if="isSelected && lookTag.type"
                class="btn btn-sm btn-green ml-4"
                @click.prevent="$_confirmLookTag"
              >
                <i class="fas fa-check mr-1" />
                {{ $t('shared.save') }}
              </button>
            </div>
          </div>
        </div>

        <div
          v-if="lookTag.id && lookTag.type"
          class="w-auto text-right whitespace-nowrap"
        >
          <span v-if="likeable" class="ml-2">
            <LikeButton
              target-type="LookTag"
              :target-id="lookTag.id"
              :liked="isLiked"
            />
          </span>
          <span v-if="wishlistable" class="ml-2">
            <WishlistButton
              target-type="LookTag"
              :target-id="lookTag.id"
              :wishlisted="isWishlisted"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextTag from './TextTag'
import ProductTag from './ProductTag'
import NewProductTag from './NewProductTag'

import LikeButton from '../LikeButton'
import WishlistButton from '../WishlistButton'

export default {
  components: {
    TextTag,
    ProductTag,
    NewProductTag,
    LikeButton,
    WishlistButton,
  },

  props: {
    uuid: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    likeable: {
      type: Boolean,
      required: true,
    },
    wishlistable: {
      type: Boolean,
      required: true,
    },
    maxImageSize: {
      type: Number,
      default: null,
    },
  },

  computed: {
    cardClasses() {
      return {
        'border-blue-500': this.isHighlighted,
        'cursor-pointer': this.editable && !this.isSelected,
      }
    },

    badgeClasses() {
      return this.isSelected ? 'badge-red' : 'badge-teal'
    },

    lookTagPosition() {
      return this.$store.getters.lookTagPositionByUuid(this.uuid)
    },

    lookTag() {
      return this.$store.getters.lookTagByUuid(this.uuid)
    },

    newProductAllowed() {
      const allowedTypes = ['business', 'admin']
      return allowedTypes.includes(this.$store.state.currentUserType)
    },

    hasChildren() {
      const children = this.$store.getters.lookTagChildrenByUuid(this.uuid)
      return children.length > 0
    },

    isMergeSource() {
      return this.$store.state.lookTagMergeSourceUuid === this.uuid
    },

    isHighlighted() {
      return this.$store.state.highlightedLookTagUuid === this.uuid
    },

    isSelected() {
      return this.$store.state.selectedLookTagUuid === this.uuid
    },

    isLiked() {
      return this.$store.state.likedLookTagIds.includes(this.lookTag.id)
    },

    isWishlisted() {
      return this.$store.state.wishlistedLookTagIds.includes(this.lookTag.id)
    },
  },

  methods: {
    $_setLookTagType(type) {
      this.$store.commit('updateLookTag', {
        uuid: this.uuid,
        type: type,
        product_id: null,
      })
    },

    $_setMergeSource() {
      if (!this.lookTag.confirmed) {
        this.$_confirmLookTag(false)
      }

      this.$store.commit('setLookTagMergeSource', this.uuid)
    },

    $_clearMergeSource() {
      this.$store.commit('setLookTagMergeSource', null)
    },

    $_splitChildren() {
      this.$store.commit('splitLookTags', this.uuid)
    },

    $_confirmLookTag(deselect = true) {
      if (!this.lookTag.confirmed) {
        this.$store.commit('confirmLookTag', this.uuid)
      }

      if (deselect) this.$_deselectLookTag()
    },

    $_removeLookTag() {
      this.$store.commit('removeLookTag', this.uuid)
    },

    $_highlightLookTag() {
      if (!this.isHighlighted) {
        this.$store.commit('highlightLookTag', this.uuid)
      }
    },

    $_dehighlightLookTag() {
      this.$store.commit('highlightLookTag', null)
    },

    $_selectLookTag() {
      if (!this.isSelected && this.editable) {
        this.$store.commit('selectLookTag', this.uuid)
      }
    },

    $_deselectLookTag() {
      this.$store.commit('selectLookTag', null)
    },
  },
}
</script>
