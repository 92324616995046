<template>
  <div class="text-center">
    <Canvas
      :is-editable="editable"
      :image-url="imageUrl"
      :image-srcset="imageSrcset"
      :class="{ 'cursor-crosshair': editable }"
      @mouseover.native="hovering = true"
      @mouseleave.native="hovering = false"
    >
      <template v-if="hovering || editable || highlightedLookTagUuid">
        <Box
          v-for="lookTag of shownLookTags"
          :id="lookTag.id"
          :key="lookTag.uuid"
          :parent-uuid="lookTag.parent_uuid"
          :uuid="lookTag.uuid"
          :x1="lookTag.x1"
          :x2="lookTag.x2"
          :y1="lookTag.y1"
          :y2="lookTag.y2"
          :is-confirmed="lookTag.confirmed"
          :is-editable="editable"
        />
      </template>
    </Canvas>
  </div>
</template>

<script>
import Canvas from './Canvas'
import Box from './Box'

import uuidv4 from '../../mixins/uuidv4'

export default {
  components: { Canvas, Box },
  mixins: [uuidv4],

  props: {
    currentUserId: {
      type: String,
      default: null,
    },
    currentUserType: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    imageSrcset: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    lookTags: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    profiles: {
      type: Array,
      required: true,
    },
    likedLookTagIds: {
      type: Array,
      default: () => [],
    },
    wishlistedLookTagIds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      hovering: false,
    }
  },

  computed: {
    highlightedLookTagUuid() {
      return this.$store.state.highlightedLookTagUuid
    },

    mergeSourceUuid() {
      return this.$store.state.lookTagMergeSourceUuid
    },

    shownLookTags() {
      let lookTags = this.$store.state.lookTags.filter(item => !item._destroy)

      if (this.editable && this.mergeSourceUuid) {
        // Show only merge targets if in merging mode
        lookTags = lookTags.filter(
          item =>
            !item.confirmed &&
            item.uuid !== this.mergeSourceUuid &&
            item.parent_uuid !== this.mergeSourceUuid
        )
      } else if (!this.hovering && this.highlightedLookTagUuid) {
        // Else show only the single highlighted box
        lookTags = lookTags.filter(
          item =>
            item.uuid === this.highlightedLookTagUuid ||
            item.parent_uuid === this.highlightedLookTagUuid
        )
      }

      return lookTags
    },
  },

  mounted() {
    this.$_savePropsToStore()
  },

  methods: {
    $_savePropsToStore() {
      // Modify look tags for storage
      const lookTagsToStore = this.lookTags.map(lookTag => {
        const product = lookTag.product_attributes || {}

        let lookTagType = null
        if (lookTag.product_id) {
          lookTagType = 'product'
        } else if (Object.keys(product).length > 0) {
          lookTagType = 'new_product'
        } else {
          lookTagType = 'text'
        }

        return {
          type: lookTagType,
          image: lookTag.cached_image_data || null,
          confirmed: true,
          _destroy: false,
          ...lookTag,
          uuid: lookTag.id || lookTag.uuid || this.$_uuidv4(),
          attributes_data: lookTag.attributes_data.map(data => ({
            ...data,
            uuid: data.id || this.$_uuidv4(),
          })),
          product_attributes: {
            ...product,
            attributes_data: (product.attributes_data || []).map(data => ({
              ...data,
              uuid: this.$_uuidv4(),
            })),
          },
        }
      })

      // Add parent_uuid based on parent_id
      lookTagsToStore.forEach(lookTag => {
        lookTag['parent_uuid'] = lookTag.parent_uuid || null
        if (lookTag.parent_id) {
          lookTag['parent_uuid'] = lookTagsToStore.find(
            parent => parent.id === lookTag.parent_id
          ).uuid
        }
      })

      // Store final data
      lookTagsToStore.forEach(lookTag =>
        this.$store.commit('addLookTag', lookTag)
      )
      this.products.forEach(product =>
        this.$store.commit('addProduct', product)
      )
      this.profiles.forEach(profile =>
        this.$store.commit('addProfile', profile)
      )
      this.$store.commit('setCurrentUserId', this.currentUserId)
      this.$store.commit('setCurrentUserType', this.currentUserType)
      this.$store.commit('addLikedLookTagIds', this.likedLookTagIds)
      this.$store.commit('addWishlistedLookTagIds', this.wishlistedLookTagIds)
    },
  },
}
</script>
