<template>
  <div>
    <NewListForm
      v-if="newWishlistTab"
      :target-id="targetId"
      :target-type="targetType"
      @wishlists-updated="$emit('wishlists-updated', $event)"
    />

    <SelectListForm
      v-else
      :target-id="targetId"
      :target-type="targetType"
      :wishlists="wishlists"
      @wishlists-updated="$emit('wishlists-updated', $event)"
    />

    <div class="text-sm mt-2">
      <a href="#" class="text-link" @click.prevent="$_toggleNewWishlistTab">
        {{ toggleNewWishlistText }}
      </a>
    </div>
  </div>
</template>

<script>
import NewListForm from './NewListForm'
import SelectListForm from './SelectListForm'

export default {
  components: {
    NewListForm,
    SelectListForm,
  },

  props: {
    targetId: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    wishlists: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      newWishlistTab: this.wishlists.length === 0,
    }
  },

  computed: {
    toggleNewWishlistText() {
      if (this.newWishlistTab) {
        return this.$t('wishlist_button.select_wishlist')
      } else {
        return this.$t('wishlist_button.new_wishlist')
      }
    },
  },

  methods: {
    $_toggleNewWishlistTab() {
      this.newWishlistTab = !this.newWishlistTab
    },
  },
}
</script>
