<template>
  <div
    class="bg-red-200 border-2 border-red-400 border-dashed rounded text-center p-5"
  >
    <h4 class="font-bold text-xl text-red-700 leading-tight">
      {{ $t('image_uploader.error') }}
    </h4>
    <div class="mt-2 text-sm overflow-x-auto">{{ error }}</div>
    <a href="#" class="mt-4 btn btn-blue" @click.prevent="$_onCancel">
      {{ $t('image_uploader.change') }}
    </a>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: String,
      required: false,
      default: '',
    },
  },

  methods: {
    $_onCancel() {
      this.$emit('canceled')
    },
  },
}
</script>
