import { Controller } from 'stimulus'
import Tribute from 'tributejs'
import axios from 'axios'

export default class extends Controller {
  initialize() {
    this.tribute = new Tribute({
      lookup: 'username',
      fillAttr: 'username',
      selectTemplate: this.selectTemplate,
      menuItemTemplate: this.menuItemTemplate,
      values: this.fetchSuggestions,
    })
  }

  connect() {
    this.tribute.attach(this.element)
  }

  disconnect() {
    if (this.tribute) {
      this.tribute.detach(this.element)
    }
  }

  selectTemplate(item) {
    return '@' + item.original.username
  }

  menuItemTemplate(item) {
    return `
      <div class="flex items-center">
        ${item.original.avatar_html}
        <div class="ml-2">${item.string}</div>
        <div class="ml-2 text-sm text-gray-600">${item.original.full_name}</div>
      </div>
    `
  }

  fetchSuggestions(text, cb) {
    const queryUrl = '/js/autocomplete/mentions'

    axios
      .get(queryUrl, {
        params: { query: text },
      })
      .then(response => {
        cb(response.data.suggestions)
      })
      .catch(error => {
        console.error(error)
        cb([])
      })
  }
}
