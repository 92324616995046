<template>
  <div class="flex flex-wrap justify-center -mx-4">
    <div class="w-full md:w-1/3 px-4 mb-4">
      <ImageUploader
        v-if="uploadable"
        dropzone-id="look-image"
        input-name="look[image]"
        upload-url="/uploads/looks"
        :max-size="maxImageSize"
        :crop-enabled="true"
        :crop-aspect-ratio="2 / 3"
        :crop-min-width="cropMinImageWidth"
        :crop-max-width="cropMaxImageWidth"
        :crop-min-height="cropMinImageHeight"
        :crop-max-height="cropMaxImageHeight"
        :cached-image-url="imageUrl"
        :cached-image-data="cachedImageData"
        :processing-callback="$_processUploadedImage"
        @state-change="$_changeUploadState"
      >
        <template #uploaded="slotProps">
          <LookImage
            :current-user-id="currentUserId"
            :current-user-type="currentUserType"
            :image-url="slotProps.imageUrl"
            :look-tags="lookTags"
            :products="products"
            :profiles="profiles"
            :editable="true"
          />
        </template>
      </ImageUploader>
      <LookImage
        v-else
        :current-user-id="currentUserId"
        :current-user-type="currentUserType"
        :image-url="imageUrl"
        :image-srcset="imageSrcset"
        :look-tags="lookTags"
        :products="products"
        :profiles="profiles"
        :editable="true"
      />
    </div>

    <div v-if="!uploadable || uploaded" class="w-full md:w-2/3 px-4 mb-4">
      <Description :value="description" />
      <LookTags :editable="true" :max-image-size="maxTagImageSize" />

      <div class="text-right">
        <a v-if="cancelUrl" :href="cancelUrl" class="btn btn-gray">
          {{ $t('shared.cancel') }}
        </a>
        <input
          type="submit"
          name="commit"
          :value="$t('shared.save')"
          class="btn btn-blue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Description from './Description'

import ImageUploader from '../ImageUploader'
import LookImage from '../LookImage'
import LookTags from '../LookTags'

import defaultLookTag from '../../mixins/defaultLookTag'
import authenticityToken from '../../mixins/authenticityToken'

export default {
  components: {
    ImageUploader,
    LookImage,
    LookTags,
    Description,
  },

  mixins: [defaultLookTag, authenticityToken],

  props: {
    currentUserId: {
      type: String,
      default: null,
    },
    currentUserType: {
      type: String,
      default: null,
    },
    uploadable: {
      type: Boolean,
      default: false,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    imageSrcset: {
      type: String,
      default: null,
    },
    cachedImageData: {
      type: String,
      default: null,
    },
    maxImageSize: {
      type: Number,
      default: null,
    },
    cropMinImageWidth: {
      type: Number,
      default: null,
    },
    cropMinImageHeight: {
      type: Number,
      default: null,
    },
    cropMaxImageWidth: {
      type: Number,
      default: null,
    },
    cropMaxImageHeight: {
      type: Number,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    cancelUrl: {
      type: String,
      default: null,
    },
    lookTags: {
      type: Array,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    profiles: {
      type: Array,
      required: true,
    },
    maxTagImageSize: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      uploaded: null,
    }
  },

  methods: {
    $_changeUploadState(uploaded) {
      this.uploaded = uploaded
    },

    $_processUploadedImage(imageUrl, processedCallback) {
      this.$store.commit('cleanLookTags')

      this.$http
        .post(
          '/js/predictions/look_tags',
          { image_url: imageUrl },
          { headers: { 'X-CSRF-Token': this.$_authenticityToken() } }
        )
        .then(response => {
          response.data.look_tags.forEach(data => {
            const lookTagData = {
              x1: data.x1,
              y1: data.y1,
              x2: data.x2,
              y2: data.y2,
            }

            this.$store.commit(
              'addLookTag',
              this.$_defaultLookTag(lookTagData, data.look_tag_type)
            )
          })
        })
        .catch(error => console.error(error))
        .finally(processedCallback)
    },
  },
}
</script>
