<template>
  <div ref="svg" class="relative flex justify-center"></div>
</template>

<script>
import { Circle } from 'progressbar.js'

export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      progressbar: undefined,
      options: {
        color: '#4fd1c5',
        trailColor: '#cbd5e0',
        strokeWidth: 4,
        trailWidth: 1,
        duration: 100,
        easing: 'linear',
        svgStyle: { width: '50%' },
        text: {
          autoStyleContainer: false,
        },
        step: (state, bar) => {
          bar.setText(Math.round(bar.value() * 100) + ' %')
        },
      },
    }
  },

  watch: {
    progress() {
      this.progressbar.animate(this.progress)
    },
  },

  mounted() {
    this.progressbar = new Circle(this.$refs.svg, this.options)
  },

  destroyed() {
    if (this.progressbar) this.progressbar.destroy()
  },
}
</script>
