require('@fortawesome/fontawesome-free/css/fontawesome')
require('@fortawesome/fontawesome-free/css/solid')
require('@fortawesome/fontawesome-free/css/regular')
require('@fortawesome/fontawesome-free/css/brands')

require('stylesheets/application')

window.Rails = require('@rails/ujs')
window.Rails.start()

window.Turbolinks = require('turbolinks')
window.Turbolinks.start()

import 'controllers'
